import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';

function Menu() {
  const navigate = useNavigate();

  // เมื่อคอมโพเนนต์ถูกโหลดครั้งแรก ให้ตรวจสอบว่ามีข้อมูลผู้ใช้ใน localStorage หรือไม่
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        // ถ้ายังไม่หมดเวลาให้ทำอะไรก็ว่างไว้
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  const handleOnlineClick = () => {
    navigate('/menu/online');
  };

  const handleStockClick = () => {
    navigate('/menu/stock');
  };

  const handleStoreClick = () => {
    navigate('/menu/online');
  };

  const handleSystemClick = () => {
    navigate('/menu/system');
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ mt: 8, p: 4, width: '100%' }}>
        <img src="/CNC.png" alt="CNC Autopart" style={{ width: '100%' }} />
        <Button onClick={handleOnlineClick} fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
          <Typography variant="h5" align="center">Online</Typography>
        </Button>
        <Button onClick={handleStockClick} fullWidth variant="contained" color="primary" sx={{ mt: 3 }}>
          <Typography variant="h5" align="center">คลังสินค้า</Typography>
        </Button>
        <Button onClick={handleStoreClick} fullWidth variant="contained" color="primary" sx={{ mt: 3 }} disabled>
          <Typography variant="h5" align="center">หน้าร้าน</Typography>
        </Button>
        <Button onClick={handleSystemClick} fullWidth variant="contained" color="primary" sx={{ mt: 3 }} >
          <Typography variant="h5" align="center">SYSTEM</Typography>
        </Button>
      </Box>
    </Container>
  );
}

export default Menu;
