import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Breadcrumbs, AppBar, Toolbar, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { emphasize, styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useAxios } from '../../lib/axios';

function SystemMenu() {
  const navigate = useNavigate();
  const axios = useAxios();

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  const handleGoBack = () => {
    navigate('/menu');
  };

  const handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        // Valid session
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);
  
  const handleLocationSearchClick = () => navigate('/menu/system/locationSearch');
  const handleStoreClick = () => navigate('/menu/online');
  const handleManageUserClick = () => navigate('/menu/system/manageUser');
  const exportDataClick = async() => {
    await axios.post('/online/export').then((response) => {
      Swal.fire({
        title: 'ดำเนินการสำเร็จ',
        icon: 'success',
        showConfirmButton: true,
      }).then(() => {
        navigate('/menu/system')
      }).catch((error) => {
        console.log(error)
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          navigate('/menu/system')
        });
      })
    }).catch((error) => {
      console.log('Full error object:', error);
      console.error('Error response data:', error.response?.data);

      const errorMessage = error.response?.data?.message || 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่';
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      }).then(() => {
        navigate('/menu/system')
      });
    });
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <StyledBreadcrumb
              component="a"
              href="/menu"
              label="Menu"
              icon={<HomeIcon fontSize="small" />}
            />
            <StyledBreadcrumb
              label="System"
              deleteIcon={<ExpandMoreIcon />}
              onDelete={handleClick}
            />
          </Breadcrumbs>
        </Box>

        <Container
          component="main"
          maxWidth="xs"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh' }}
        >
          <Box sx={{ mt: 8, p: 4, width: '100%' }}>
            <img src="/CNC.png" alt="CNC Autopart" style={{ width: '100%' }} />
            <Button
              onClick={exportDataClick}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              <Typography variant="h5" align="center">Export Part1</Typography>
            </Button>
            <Button
              onClick={handleLocationSearchClick}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              <Typography variant="h5" align="center">Location<br /> (Dashboard)</Typography>
            </Button>
            <Button
              onClick={handleStoreClick}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              disabled
            >
              <Typography variant="h5" align="center">History</Typography>
            </Button>
            <Button
              onClick={handleManageUserClick}
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              <Typography variant="h5" align="center">Manage User</Typography>
            </Button>
          </Box>
        </Container>

        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FFFFFF 30%, #FFFFFF 90%)' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
              sx={{
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#ffcccc',
                },
                color: 'black',
                borderRadius: '50%',
                padding: '10px',
              }}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Container>
    </>
  );
}

export default SystemMenu;
