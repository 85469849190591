import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Breadcrumbs, AppBar, Toolbar, IconButton, Chip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { emphasize, styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';

// Styled Breadcrumb component
const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
  height: theme.spacing(3),
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightRegular,
  '&:hover, &:focus': {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
      0.06
    ),
  },
  '&:active': {
    boxShadow: theme.shadows[1],
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
      0.12
    ),
  },
}));

function ManageUserMenu() {
  const navigate = useNavigate();

  // Navigation handlers
  const handleGoBack = () => navigate('/menu/system');
  const handleClick = (event) => {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  };

  // Effect to handle user session
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        // Valid session
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  // Navigation button handlers
  const handleCreateClick = () => navigate('/menu/system/manageUser/Create');
  const handleEditClick = () => navigate('/menu/system/manageUser/Edit');
  const handleDeleteClick = () => navigate('/menu/system/manageUser/Delete');

  return (
    <>
    <Container maxWidth="lg">
      {/* Breadcrumb Box */}
      <Box
        sx={{
          width: '100%',
          padding: '20px',
          paddingBottom: '10px',
          borderBottom: '1px solid #ccc',
          backgroundColor: 'background.paper',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="/menu"
            label="Menu"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb component="a" href="/menu/system" label="System" />
          <StyledBreadcrumb
            label="Manage User"
            deleteIcon={<ExpandMoreIcon />}
            onDelete={handleClick}
          />
        </Breadcrumbs>
      </Box>

      {/* Main Content Container */}
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 96px)', // Adjust for AppBar height
          py: 4,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <img src="/CNC.png" alt="CNC Autopart" style={{ width: '100%' }} />
          <Button
            onClick={handleCreateClick}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            <Typography variant="h5" align="center">Create User</Typography>
          </Button>
          <Button
            onClick={handleEditClick}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled
          >
            <Typography variant="h5" align="center">Edit User</Typography>
          </Button>
          <Button
            onClick={handleDeleteClick}
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            disabled
          >
            <Typography variant="h5" align="center">Delete User</Typography>
          </Button>
        </Box>
      </Container>

      {/* Bottom AppBar for Navigation */}
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FFFFFF 30%, #FFFFFF 90%)' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoBack}
            sx={{
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#ffcccc',
              },
              color: 'black',
              borderRadius: '50%',
              padding: '10px',
            }}
          >
            <ArrowBack />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Container>
    </>
  );
}

export default ManageUserMenu;
