import React, { useEffect, useState, useCallback } from 'react';
import { CustomPaper } from '../../styles';
import { AppBar, Toolbar, Typography, IconButton, Box, Button, Container } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../lib/axios';
import Swal from 'sweetalert2';
import { useItemDetail } from '../../context/mainContext';

function StockDetail({ data }) {
    const navigate = useNavigate();
    const axios = useAxios();
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState('');
    const [isSKUInserted, setIsSKUInserted] = useState(false);
    const sku = searchParams.get('sku');
    const { type, orderDetail, setOrderDetail} = useItemDetail();
    const modifiedData = {
      skuID: null,
      itemCode: null
    };

    const handleGoHome = () => {
      navigate('/menu');
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const insertSKU = useCallback(async (sku) => {
      if(type==='I'){
        await axios.post('/items/' + sku).then((response) => {
          Swal.fire({
            title: 'ดำเนินการสำเร็จ',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          }).then(() => {
            modifiedData.skuID = response.data.id;
            modifiedData.itemCode = response.data.itemCode;
            setOrderDetail(modifiedData);
            setIsSKUInserted(true);
          }).catch((error) => {
            console.log(error)
            Swal.fire({
              title: 'ไม่สามารถดำเนินการได้',
              text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
              icon: 'error',
              confirmButtonText: 'ตกลง',
              showCloseButton: false,
              showCancelButton: false,
            }).then(() => {
              setOrderDetail(null)
              navigate('/menu')
            });
          })
        })
      }else if(type==='D'){
        modifiedData.itemCode = sku;
        setOrderDetail(modifiedData);
        setIsSKUInserted(true);
      }else{
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          setOrderDetail(null)
          navigate('/menu')
        });
      }
    }, [axios, setOrderDetail]);

    const handleScanShelf = async () => {
      navigate('/menu/stock/qrCodeLOCScanner?type='+ type+"?sku="+sku);
    };

    useEffect(() => {
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          const { username, expiration } = JSON.parse(storedUserData);
          if (expiration && Date.now() < expiration) {
            setUsername(username);
            if (!type) {
              navigate('/menu');
              return;
            }
            if (!isSKUInserted) {
              insertSKU(sku);
            }
          } else {
            localStorage.removeItem('userData');
            navigate('/');
          }
        } else {
          navigate('/');
        }
    }, [navigate, insertSKU, sku, type, isSKUInserted]);

    return (
        <>
        <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
              Detail: {type == 'I' ? 'ผูกสินค้า' : 'ลบสินค้า'}
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>

        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <CustomPaper sx={{ paddingBottom: '80px', textAlign: 'center' }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                    SKU Number: 
                </Typography>
                <Typography variant='h6' sx={{ mb: 2 }}>
                {orderDetail && orderDetail?.itemCode}
                </Typography>
                <Box mt={4}>
                    <Button variant="contained" color="primary" onClick={handleScanShelf}>
                        แสกนชั้นวางของ<br/>SCAN LOC
                    </Button>
                </Box>
            </CustomPaper>
        </Container>

        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
            <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="back" onClick={handleGoBack}>
                    <ArrowBack />
                </IconButton>
            </Toolbar>
        </AppBar>
        </>
    );
}

export default StockDetail;
