import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../lib/axios';
import { Container, Typography, TextField, Button, Grid, Box } from '@mui/material';
import Swal from 'sweetalert2';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const axios = useAxios();

  let userData = {
    username: null,
    expiration: null,
    role: null
  };

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    try {
      const response = await axios.post('/users/login', { name: username });
      console.log("response: ", response);
      userData.username = username;
      userData.expiration = Date.now() + 8 * 60 * 60 * 1000;
      userData.role = response.data.role; // Assuming the role is in response.data
      const userDataJSON = JSON.stringify(userData);
      localStorage.setItem('userData', userDataJSON);
      navigate('/menu');
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      });
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        setUsername(username);
        navigate('/menu');
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box sx={{ mt: 8, p: 4, width: '100%' }}>
        <Typography component="h1" variant="h5" align="center">
          CNC Autopart
        </Typography>
        <img src="/CNC.png" alt="CNC Autopart" style={{ width: '100%', marginTop: '10px', marginBottom: '20px' }} />
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="username"
                name="username"
                required
                fullWidth
                id="username"
                label="Username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit" // Make sure the button is of type "submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
