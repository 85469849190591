import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Container, Box, Grid, Button, TextField } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { QrReader } from 'react-qr-reader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useItemDetail } from '../../context/mainContext';

function QRCodeScanner() {
  const navigate = useNavigate();
  // const qrReader = React.createRef();
  const [data, setData] = useState('No result');
  const [username, setUsername] = useState('');
  const { setOrderDetail, type, setType } = useItemDetail();
  const [searchParams] = useSearchParams();
  const typeQuery = searchParams.get('type');
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleGoBack = () => {
    navigate('/menu/online');
  };

  const handleGoHome = () => {
    navigate('/menu');
  };

  const toggleInput = () => {
    setShowInput(!showInput);
    setInputValue('');
  };

  function playBeepSound() {
    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);

    oscillator.connect(audioContext.destination);
    oscillator.start();

    setTimeout(() => {
        oscillator.stop();
    }, 500);
  }

  useEffect(() => {   
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        if(!type){
          if(typeQuery){
           setType(typeQuery);
          }else{
            navigate('/menu');
          }
        }
        setUsername(username);
        setOrderDetail(null);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    }else{
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
            {type === 'I' ? "Check Bill" : "Cancel Bill"}
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <QrReader
              constraints={{
                facingMode: "environment",
                width: 1920 ,
                height: 1920 ,
                aspectRatio: { exact: 1 },
              //  fps: 30,
                zoom: 0
              }}
              onResult={(result, error) => {
                if (!!result) {
                  playBeepSound();
                  console.log(result)
                  let data = result?.text;
                  console.log(data)
                  let parts = data.split(" ");
                  var requiredPart = parts[0];
                  console.log(requiredPart)
                  setData(result?.text);
                  navigate('/menu/online/detail?trackingNo=' + requiredPart + '&type=' + type);
                  // qrReader.current.stop();
                }

                if (!!error) {
                  // console.info(error);
                }
              }}
              style={{ width: '100%' }}
            />
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={toggleInput}>
                  {showInput ? 'Cancel Input' : 'Enter Tracking Number'}
                </Button>
              </Grid>
              {showInput && (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    label="Enter Tracking Number"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <Button variant="contained" onClick={() => {
                    if (inputValue.trim() !== '') {
                      playBeepSound();
                      setData(inputValue.trim());
                      navigate('/menu/online/detail?trackingNo=' + inputValue.trim() + '&type=' + type);                                      
                    }
                  }}>
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Scanned Data:</Typography>
              <Typography variant="body1">{data}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            {/* Your back button */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
      </AppBar>
    </>
  );
}

export default QRCodeScanner;
