import React, { useEffect, useState, useCallback } from 'react';
import { CustomPaper } from '../../styles';
import Divider from '@mui/material/Divider/Divider';
import { AppBar, Toolbar, Typography, IconButton, Box, Grid, Button } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../lib/axios'
import Swal from 'sweetalert2'
import { useItemDetail } from '../../context/mainContext'; 

function LOCDetail({ data }) {
    const navigate = useNavigate();
    const axios = useAxios();
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState('');
    const [isShelfInserted, setIsShelfInserted] = useState(false);
    const shelf = searchParams.get('shelf');
    const { type, orderDetail, setOrderDetail, SKU } = useItemDetail();
    const modifiedData = {
      shelfCodeID: null,
      SKUCodeID:null,
      itemCode: null,
      shelfName: null,
      shelfRemark: null,
      createdDate: null,
      updatedDate: null,
      createdBy: null,
      updatedBy: null
    };

    const handleGoHome = () => {
      navigate('/menu');
    };

    const handleGoBack = () => {
      navigate(-1);
    };

    const insertShelf = useCallback(async (shelf) => {
      if(type==='I'){
        await axios.post('/shelfs/' + shelf).then((response) => {
          Swal.fire({
            title: 'ดำเนินการสำเร็จ',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true
          }).then(() => {
            modifiedData.shelfCodeID = response.data.id;
            modifiedData.shelfName = response.data.shelfName;
            modifiedData.shelfRemark = response.data.shelfRemark;
            modifiedData.SKUCodeID = orderDetail.skuID;
            modifiedData.itemCode = orderDetail.itemCode;
            setIsShelfInserted(true);
            setOrderDetail(modifiedData);
          }).catch((error) => {
            console.log(error)
            Swal.fire({
              title: 'ไม่สามารถดำเนินการได้',
              text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
              icon: 'error',
              confirmButtonText: 'ตกลง',
              showCloseButton: false,
              showCancelButton: false,
            }).then(() => {
              setOrderDetail(null)
              navigate('/menu')
            });
          })
        })
      }else if(type==='D'){
        modifiedData.shelfName = shelf;        
        modifiedData.itemCode = orderDetail.itemCode;
        setIsShelfInserted(true);
        setOrderDetail(modifiedData)
      } else{
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          setOrderDetail(null)
          navigate('/menu')
        });
      }   
    }, [axios, setOrderDetail]);

    const handleMappingSKUANDShelf = async() => {
      if(type==="I"){
        const dataToSend = {
          sku_code : orderDetail.itemCode,
          shelf_code : orderDetail.shelfName,
          sku_code_id : orderDetail.SKUCodeID,
          shelf_code_id : orderDetail.shelfCodeID
        }

        await axios.post('/stocks' ,dataToSend).then((response) => {
          Swal.fire({
            title: 'ดำเนินการสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
            showCloseButton: false,
            showCancelButton: false,
          }).then(() => {
            setOrderDetail(null)
            navigate('/menu/stock')
          })
        })
        .catch((error) => {
            console.log(error)
            Swal.fire({
              title: 'ไม่สามารถดำเนินการได้',
              text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
              icon: 'error',
              confirmButtonText: 'ตกลง',
              showCloseButton: false,
              showCancelButton: false,
            }).then(() => {
              setOrderDetail(null)
              navigate('/menu')
            });
        })
      }else if(type==='D'){
        await axios.delete('/stocks' ,{ data: { sku_code: orderDetail.itemCode, shelf_code : orderDetail.shelfName}, headers: {}}).then((response) => {
          Swal.fire({
            title: 'ดำเนินการสำเร็จ',
            icon: 'success',
            confirmButtonText: 'ตกลง',
            showCloseButton: false,
            showCancelButton: false,
          }).then(() => {
            setOrderDetail(null)
            navigate('/menu/stock')
          })
        })
        .catch((error) => {
            console.log(error)
            Swal.fire({
              title: 'ไม่สามารถดำเนินการได้',
              text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
              icon: 'error',
              confirmButtonText: 'ตกลง',
              showCloseButton: false,
              showCancelButton: false,
            }).then(() => {
              setOrderDetail(null)
              navigate('/menu')
            });
        })
      }else{
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          setOrderDetail(null)
          navigate('/menu')
        });
      }
    };

    useEffect(() => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        const { username, expiration } = JSON.parse(storedUserData);
        if (expiration && Date.now() < expiration) {
          setUsername(username);
          if (!type) {
            navigate('/menu');
            return;
          }
          if (!isShelfInserted) {
            insertShelf(shelf);
          }
        } else {
          localStorage.removeItem('userData');
          navigate('/');
        }
      } else {
        navigate('/');
      }
    }, [navigate, insertShelf, shelf, type, isShelfInserted]);

    return (
      <>
        <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar sx={{ justifyContent: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="home"
              onClick={handleGoHome}
            >
              <Home />
            </IconButton>
            <Typography variant="h6" component="div">
              Detail: {type === 'I' ? 'ผูกสินค้า' : 'ลบสินค้า'}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
              {username}
            </Typography>
          </Toolbar>
        </AppBar>
        <CustomPaper sx={{ paddingBottom: '80px' }}>
          <Typography variant='h6'>ชื่อสินค้า : </Typography>
          <Typography variant='h6'>{orderDetail && orderDetail?.itemCode}</Typography>
          <Typography variant='h6'>LOC Number : </Typography>
          <Typography variant='h4'>{orderDetail && orderDetail?.shelfName}</Typography>
          <Box mt={4} textAlign="center">
            <Button variant="contained" color="primary" onClick={handleMappingSKUANDShelf}>
              SEND
            </Button>
          </Box>
        </CustomPaper>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
        </AppBar>
      </>
    );
}

export default LOCDetail;
