import { useState, createContext, useContext } from "react";

const OnlineItemDetailContext = createContext();

export const ItemDetailProvider = ({ children }) => {
  const [orderDetail, setOrderDetail] = useState(null);  
  const [type, setType] = useState(null);

  return (
    <OnlineItemDetailContext.Provider value={{ orderDetail, setOrderDetail, type, setType }}>
      {children}
    </OnlineItemDetailContext.Provider>
  );
};

export const useItemDetail = () => {
  const context = useContext(OnlineItemDetailContext);
  if (!context) {
    throw new Error("useItemDetail must be used within an ItemDetailProvider");
  }
  return context;
};
