import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Container, Box, Grid, TextField, Button } from '@mui/material';
import { ArrowBack, Home, SwapHoriz } from '@mui/icons-material';
import { QrReader } from 'react-qr-reader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useItemDetail } from '../../context/mainContext';

function QRCodeLOCScanner() {
  const navigate = useNavigate();
  const [data, setData] = useState('No result');
  const [username, setUsername] = useState('');
  const { type, setType } = useItemDetail();
  const [searchParams] = useSearchParams();
  const typeQuery = searchParams.get('type');
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleGoHome = () => {
    navigate('/menu');
  };

  function playBeepSound() {
    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);

    oscillator.connect(audioContext.destination);
    oscillator.start();

    setTimeout(() => {
        oscillator.stop();
    }, 500);
  }

  useEffect(() => {   
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        if (!type) {
          if (typeQuery) {
            setType(typeQuery);
          } else {
            navigate('/menu');
          }
        }
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  const toggleInput = () => {
    setShowInput(!showInput);
    setInputValue('');
  };

  return (
    <>
      <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
              {type === 'I' ? "ผูกสินค้า" : "ลบสินค้า"}
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <QrReader
              style={{ width: '100%', display: showInput ? 'none' : 'block' }}
              constraints={{
                facingMode: "environment",
                width: 1920 ,
                height: 1920 ,
                aspectRatio: { exact: 1 },
                zoom: 0
              }}
              onResult={(result, error) => {
                if (!!result) {
                  playBeepSound();
                  let data = result?.text;
                  let parts = data.split(" ");
                  var requiredPart = parts[0];
                  setData(result?.text);
                  navigate('/menu/stock/locdetail?shelf=' + requiredPart.trim());
                }
                if (!!error) {
                  console.info(error);
                }
              }}
            />
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={toggleInput}>
                  {showInput ? 'Cancel Input' : 'Enter LOC Number'}
                </Button>
              </Grid>
              {showInput && (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    label="Enter LOC Number"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <Button variant="contained" onClick={() => {
                    if (inputValue.trim() !== '') {
                      playBeepSound();
                      setData(inputValue.trim());
                      navigate('/menu/stock/locdetail?shelf=' + inputValue.trim());                      
                    }
                  }}>
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Scanned Data: </Typography>
              <Typography variant="body1">{data}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
      </AppBar>
    </>
  );
}

export default QRCodeLOCScanner;