import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useAxios } from '../../../lib/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { AppBar, Breadcrumbs, Chip, InputLabel, Toolbar } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { emphasize, styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';

function UserCreate() {
  const navigate = useNavigate();
  const axios = useAxios();
  const [username, setUsername] = useState('');
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    role: 'staff',
  });

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });
  const handleGoBack = () => {
    navigate('/menu/system/manageUser');
  };
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await axios.post('/users/register', user);
      Swal.fire({
        title: 'ดำเนินการสำเร็จ',
        icon: 'success',
        showConfirmButton: true,
        timer: 1500,
        timerProgressBar: true
      }).then(() => {
        navigate('/menu/system/manageUser');
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      }).then(() => {
        navigate('/menu');
      });
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {        
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);
  return (
    <>
        <Box sx={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="/menu"
                    label="Menu"
                    icon={<HomeIcon fontSize="small" />}
                />
                <StyledBreadcrumb component="a" href="/menu/system" label="System" />
                <StyledBreadcrumb component="a" href="/menu/system/manageUser" label="Manage User" />
                <StyledBreadcrumb
                    label="Registration User"
                    deleteIcon={<ExpandMoreIcon />}
                    onDelete={handleClick}
                />
            </Breadcrumbs>
            <Box>
                <Typography variant="body1" sx={{ textAlign: 'center' }}>{username}</Typography>
            </Box>
        </Box>
        <Grid container justifyContent="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={8} md={6}>
                
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: 'white',
                }}
                >
                <Typography variant="h4" component="h1" gutterBottom>
                    Registration User
                </Typography>
                <TextField
                    label="Name"
                    name="name"
                    value={user.name}
                    onChange={handleInputChange}
                    fullWidth
                />
                {/* <TextField
                    label="Email"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                    fullWidth
                />
                <TextField
                    label="Password"
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleInputChange}
                    fullWidth
                /> */}
                <InputLabel id="Role">Role</InputLabel>
                <Select
                    labelId="Role"
                    id="Role"
                    value={user.role}
                    label="Role"
                    onChange={handleInputChange}
                    fullWidth
                >
                    <MenuItem value="staff">Staff</MenuItem>
                    <MenuItem value="superadmin">Super Admin</MenuItem>
                </Select>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
                </Box>
            </Grid>
        </Grid>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FFFFFF 30%, #FFFFFF 90%)' }}>
          <Toolbar>
            {/* Your back button */}
            <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoBack}
            sx={{
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#ffcccc',
              },
              color: 'black',
              borderRadius: '50%',
              padding: '10px',
            }}
          >
            <ArrowBack />
          </IconButton>
          </Toolbar>
        </AppBar>
    </>
  );
}

export default UserCreate;
