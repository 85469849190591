import axios from 'axios';

const httpClientAxios = axios.create({
    baseURL: "https://api.cnc789.com/cnc-autopart/"
});

// console.log("httpClientAxios: ", httpClientAxios)
// let isRefreshing = false;
// let failedQueue = [];

export const useAxios = () => {

    httpClientAxios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            // const originalRequest = error.config;
            // if (error.response) {
                // if (error.response.status === 401) {
                //     if (error.response.status === 401 && !originalRequest._retry) {
                //         if (isRefreshing) {
                //             return new Promise((resolve, reject) => {
                //                 failedQueue.push({ resolve, reject });
                //             })
                //                 .then((token) => {
                //                     originalRequest.headers['Authorization'] = 'Bearer ' + token;
                //                     return httpClientAxios(originalRequest);
                //                 })
                //                 .catch((err) => {
                //                     return Promise.reject(err);
                //                 });
                //         }

                //         originalRequest._retry = true;
                //         isRefreshing = true;
                //         const refreshToken = Cookies.get('refreshToken');

                //         return new Promise((resolve, reject) => {
                //             axios
                //                 .post('/auth/refresh', { refreshToken })
                //                 .then(({ data }) => {
                //                     Cookies.set('accessToken', data.accessToken);
                //                     Cookies.set('refreshToken', data.refreshToken);
                //                     httpClientAxios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
                //                     originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
                //                     processQueue(null, data.accessToken);
                //                     resolve(httpClientAxios(originalRequest));
                //                 })
                //                 .catch((err) => {
                //                     processQueue(err, null);
                //                     reject(err);
                //                 })
                //                 .finally(() => {
                //                     isRefreshing = false;
                //                 });
                //         });
                //     }
                // } else if (error.response.status === 403) {
                //     logout();
                //     return Promise.reject(error);
                // } else {
                //     return Promise.reject(error);
                // }

                // return Promise.reject(error.response.status);
            // } else {
                return Promise.reject(error);
            // }
        },
    );

    return httpClientAxios;
};
