import React from 'react';
import { createRoot } from 'react-dom/client'; // เปลี่ยนจาก 'react-dom' เป็น 'react-dom/client'
import './index.css';
import RouteJS from './RouteJS';
import reportWebVitals from './reportWebVitals';
import { ItemDetailProvider } from './context/mainContext'; // Import ItemDetailProvider

const root = createRoot(document.getElementById('root')); // เปลี่ยนจาก ReactDOM.createRoot เป็น createRoot

root.render(
  <React.StrictMode>
    <ItemDetailProvider> {/* Use ItemDetailProvider */}
      <RouteJS />
    </ItemDetailProvider>
  </React.StrictMode>
);

reportWebVitals();
