import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Container, Button, Box, Grid, styled } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { useItemDetail } from '../../context/mainContext';

const PlatformButton = styled(Button)({
  width: '50%', // กำหนดความกว้างเท่ากับ 50% ของพ่อคู่
  fontSize: '1.2rem', // ขนาดตัวอักษร
  margin: 'auto', // จัดตำแหน่งกลางตามแนวนอน
});

function StockMenu() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const { setType } = useItemDetail();

  useEffect(() => {   
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);
  
  const handleScanBill = (i) => {
    setType(i);
    navigate('/menu/stock/qrCodeStockScanner?type=' + i);
  };

  const handleGoBack = () => {
    navigate('/menu');
  };

  const handleGoHome = () => {
    navigate('/menu');
  };

  return (
    <>
      <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
              คลังสินค้า
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="xs" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={() => handleScanBill('I')}
                fullWidth
                variant="contained"
                color="success"
                sx={{ py: 2 }}
              >
                <Typography component="h2" variant="h5" align="center">ผูกสินค้า</Typography>
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={() => handleScanBill('D')}
                variant="contained"
                color="error"
                sx={{ py: 1, px: 2, width: 'auto' }}
              >
                <Typography component="h2" variant="h5" align="center">ลบสินค้า</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoBack}
          >
            <ArrowBack />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default StockMenu;
