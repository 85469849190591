import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Button, Typography, Box } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAxios } from '../../lib/axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  borderRight: '2px solid rgba(255, 255, 255, 0.12)',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  borderLeft: `4px solid transparent`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));

const columns = [
  { id: 'rowNumber', label: '#', minWidth: 100 },
  { id: 'code', label: 'Code', minWidth: 100 },
  { id: 'LOC', label: 'LOC', minWidth: 100 },
];

export default function SearchTable() {
  const navigate = useNavigate();
  const axios = useAxios();
  const [username, setUsername] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState({
    sku_code: '',
    shelf_code: '',
    page: 1,
    page_size: 10,  // Default value to match initial rowsPerPage
  });
  const [rows, setRows] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false); // New state to track search initiation

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchInput({ ...searchInput, [event.target.name]: event.target.value });
  };

  const handleSearch = async () => {
    const updatedSearchInput = { ...searchInput, page_size: rowsPerPage };
    console.log('Search Criteria:', updatedSearchInput);
    await axios.post('/stocks/search', updatedSearchInput).then((response) => {
      const data = response.data.content;

      const mappedRows = data.map((item, index) => ({
        rowNumber: index + 1,
        code: item.item.itemCode,
        LOC: item.shelf.shelfName,
      }));

      setRows(mappedRows);
    })
    .catch((error) => {
      console.log('Full error object:', error);
      console.error('Error response data:', error.response?.data);

      const errorMessage = error.response?.data?.message || 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่';
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      }).then(() => {
        // navigate('/menu')
      });
    });
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {        
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  // Effect to handle search when rowsPerPage changes, but not on initial render
  useEffect(() => {
    if (searchInitiated) {
      handleSearch();
    }
  }, [rowsPerPage, searchInitiated]);

  const handleSearchButtonClick = () => {
    setSearchInitiated(true); // Indicate that the search has been initiated
    handleSearch();
  };

  const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
      theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[800];
    return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
        backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
        boxShadow: theme.shadows[1],
        backgroundColor: emphasize(backgroundColor, 0.12),
      },
    };
  });

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <Paper sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="/menu"
            label="Menu"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb component="a" href="/menu/system" label="System" />
          <StyledBreadcrumb
            label="Location(Dashboard)"
            deleteIcon={<ExpandMoreIcon />}
            onDelete={handleClick}
          />
        </Breadcrumbs>
        <Box>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>{username}</Typography>
        </Box>
      </Box>
      <div style={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
          <TextField
            id="sku_code"
            name="sku_code"
            label="SKU Code"
            variant="outlined"
            fullWidth
            style={{ marginRight: '10px', flex: '1 0 100px' }}
            value={searchInput.sku_code}
            onChange={handleSearchChange}
          />
          <TextField
            id="shelf_code"
            name="shelf_code"
            label="Shelf Code"
            variant="outlined"
            fullWidth
            style={{ marginRight: '10px', flex: '1 0 100px' }}
            value={searchInput.shelf_code}
            onChange={handleSearchChange}
          />
          <Button variant="contained" onClick={handleSearchButtonClick}>Search</Button>
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align="center">
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <StyledTableRow key={row.code} hover>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      {row[column.id]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
