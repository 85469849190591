import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Menu from './components/Menu';
import QRCodeScanner from './components/Online/QRCodeScanner';
import OnlineDetail from './components/Online/Detail';
import { useEffect } from 'react';
import OnlineMenu from './components/Online/Menu';
import ItemScanner from './components/Online/ItemScanner';
import LocationSearch from './components/System/LocationSearch';
import StockMenu from './components/Stock/Menu';
import QRCodeStockScanner from './components/Stock/QRCodeSKUScanner';
import QRCodeLOCScanner from './components/Stock/QRCodeLOCScanner';
import StockDetail from './components/Stock/SKUDetail';
import LOCDetail from './components/Stock/LOCDetail';
import SystemMenu from './components/System/Menu';
import ManageUserMenu from './components/System/ManageUser/Menu';
import UserCreate from './components/System/ManageUser/Create';
import UserEdit from './components/System/ManageUser/Edit';
import UserDelete from './components/System/ManageUser/Delete';

function RouteJS() {
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() >= expiration) {
        localStorage.removeItem('userData');
      }
    }
  }, []);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/menu/online" element={<OnlineMenu />} />
        <Route path="/menu/online/qrCodeScanner" element={<QRCodeScanner />} />
        <Route path="/menu/online/detail" element={<OnlineDetail />} />
        <Route path="/menu/online/detail/itemScanner" element={<ItemScanner/>} />

        <Route path="/menu/stock/" element={<StockMenu/>} />
        <Route path="/menu/stock/qrCodeStockScanner" element={<QRCodeStockScanner />} />
        <Route path="/menu/stock/stockdetail" element={<StockDetail />} />
        <Route path="/menu/stock/qrCodeLOCScanner" element={<QRCodeLOCScanner />} />
        <Route path="/menu/stock/locdetail" element={<LOCDetail />} />

        <Route path="/menu/system/" element={<SystemMenu/>} />
        <Route path="/menu/system/locationSearch" element={<LocationSearch/>} />

        <Route path="/menu/system/manageUser" element={<ManageUserMenu/>} />
        <Route path="/menu/system/manageUser/Create" element={<UserCreate/>} />
        <Route path="/menu/system/manageUser/Edit" element={<UserEdit/>} />
        <Route path="/menu/system/manageUser/Delete" element={<UserDelete/>} />
      </Routes>
    </Router>
  );
}

export default RouteJS;
