import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Button, Typography, Box, Checkbox } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAxios } from '../../../lib/axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  paddingLeft: 16,
  paddingRight: 16,
  borderRight: '2px solid rgba(255, 255, 255, 0.12)',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  borderLeft: `4px solid transparent`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));

const columns = [
  { id: 'checkbox', label: '#', minWidth: 100 },
  { id: 'id', label: 'ID', minWidth: 100 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'role', label: 'Role', minWidth: 100 },
];

export default function UserDelete() {
  const navigate = useNavigate();
  const axios = useAxios();
  const [username, setUsername] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState({
    sku_code: '',
    shelf_code: '',
    page: 1,
    page_size: 10,  // Default value to match initial rowsPerPage
  });
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchInitiated, setSearchInitiated] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchInput({ ...searchInput, [event.target.name]: event.target.value });
  };

  const handleSearch = async () => {
    const updatedSearchInput = { ...searchInput, page_size: rowsPerPage };
    console.log('Search Criteria:', updatedSearchInput);
    try {
      const response = await axios.post('/stocks/search', updatedSearchInput);
      const data = response.data.content;

      const mappedUsers = data.map((item, index) => ({
        id: item.id,
        name: item.name,
        role: item.role,
      }));

      setUsers(mappedUsers);
    } catch (error) {
      console.error('Error searching users:', error);
      const errorMessage = error.response?.data?.message || 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่';
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      });
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        setUsername(username);
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users?offset=0&limit=1000');
        const fetchedUsers = response.data.content.map(user => ({
          id: user.id,
          name: user.name,
          role: user.role,
        }));
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error as needed
      }
    };

    fetchUsers();
  }, [axios]);

  useEffect(() => {
    if (searchInitiated) {
      handleSearch();
    }
  }, [rowsPerPage, searchInitiated]);

  const handleSearchButtonClick = () => {
    setSearchInitiated(true);
    handleSearch();
  };

  const handleCheckboxChange = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleDeleteUsers = async () => {
    try {
      const response = await axios.post('/users/delete', { users: selectedUsers });
      console.log('Delete response:', response.data);
      // Handle success message or update UI as needed
    } catch (error) {
      console.error('Error deleting users:', error);
      // Handle error as needed
    }
  };

  const StyledBreadcrumb = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800], 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800], 0.12),
    },
  }));

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  return (
    <Paper sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="/menu"
            label="Menu"
            icon={<HomeIcon fontSize="small" />}
          />
          <StyledBreadcrumb component="a" href="/menu/system" label="System" />
          <StyledBreadcrumb
            label="Location(Dashboard)"
            deleteIcon={<ExpandMoreIcon />}
            onDelete={handleClick}
          />
        </Breadcrumbs>
        <Box>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>{username}</Typography>
        </Box>
      </Box>
      <div style={{ padding: '20px', paddingBottom: '10px', borderBottom: '1px solid #ccc', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
          <TextField
            id="sku_code"
            name="sku_code"
            label="SKU Code"
            variant="outlined"
            fullWidth
            style={{ marginRight: '10px', flex: '1 0 100px' }}
            value={searchInput.sku_code}
            onChange={handleSearchChange}
          />
          <TextField
            id="name"
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            style={{ marginRight: '10px', flex: '1 0 100px' }}
            value={searchInput.name}
            onChange={handleSearchChange}
            disabled
          />
          <TextField
            id="shelf_code"
            name="shelf_code"
            label="Shelf Code"
            variant="outlined"
            fullWidth
            style={{ marginRight: '10px', flex: '1 0 100px' }}
            value={searchInput.shelf_code}
            onChange={handleSearchChange}
          />
          <Button variant="contained" onClick={handleSearchButtonClick}>Search</Button>
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align="center">
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <StyledTableRow key={user.id} hover>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleCheckboxChange(user.id)}
                    />
                  </TableCell>
                  <TableCell align="center">{user.id}</TableCell>
                  <TableCell align="center">{user.name}</TableCell>
                  <TableCell align="center">{user.role}</TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Box sx={{ padding: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={handleDeleteUsers} disabled={selectedUsers.length === 0}>Delete Selected Users</Button>
      </Box>
    </Paper>
  );
}
