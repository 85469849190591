import React from 'react';
import Box from '@mui/material/Box/Box';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid/Grid';

export const Container = styled(Box)(({ theme }) => ({
    h1: {
        color: theme.palette.grey[900],
    },

    h3: {
        color: '#52575C',
        '& span': {
            color: '#225C92',
        },
    },
}));

export const CustomPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),

    '& .paper-header': {
        margin: theme.spacing(2),
    },
}));

// export const PaperItem = ({
//     title,
//     description,
//     descriptionColor,
//     boldDescription,
// }) => {
//     const theme = useTheme();
//     return (
//         React.createElement(Box, { margin: 2 },
//             React.createElement(Typography, {
//                 variant: "subtitle1",
//                 gutterBottom: true,
//                 sx: {
//                     color: theme.palette.text.secondary,
//                 }
//             }, title),
//             React.createElement(Typography, {
//                 variant: "h6",
//                 gutterBottom: true,
//                 sx: {
//                     color: descriptionColor || theme.palette.grey[900],
//                     fontWeight: boldDescription ? 'bold' : 'normal',
//                 }
//             }, description)
//         )
//     );
// };
export const PaperItem = ({
    title,
    description,
    descriptionColor,
    boldDescription,
}) => {
    const theme = useTheme();
    return (
        <Grid item xs={6} md={3}>
            <Box margin={2}>
                <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // จัดตำแหน่ง text และ description ด้านซ้าย
                    }}
                >
                    <span>{title}</span>
                    <span style={{ marginLeft: 'auto', color: descriptionColor || theme.palette.grey[900], fontWeight: boldDescription ? 'bold' : 'normal' }}>{description}</span>
                </Typography>
            </Box>
        </Grid>
    );
};





export const FormGroupContainer = styled(Grid)(() => ({
    padding: '0 5rem',
    marginTop: 2,
}));
