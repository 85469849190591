import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Container, Box, Grid, Button, TextField } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { QrReader } from 'react-qr-reader';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { useAxios } from '../../lib/axios'
import { useItemDetail } from '../../context/mainContext'; 
import Swal from 'sweetalert2'

function ItemScanner() {
  const navigate = useNavigate();
  // const axios = useAxios()
  const [data, setData] = useState('No result');
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState('');
  const {type, orderDetail, setOrderDetail } = useItemDetail();

  // // Get a specific query parameter
  const trackingNo = searchParams.get('trackingNo');
  const skuid = searchParams.get('skuid');
  const sku = searchParams.get('sku');
  const [inputValue, setInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);
  const skipSKUCode = 'SKIPSKUVERIFY'
  // let sku = null;
  // if (orderDetail !== null && Array.isArray(orderDetail)) {
  //   sku = orderDetail.find(item => item.id == id)?.sku || 'SKU Not Found';
  // }
  
  const toggleInput = () => {
    setShowInput(!showInput);
    setInputValue('');
  };
  
  const handleGoHome = () => {
    navigate('/menu');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  function playBeepSound() {
    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(1000, audioContext.currentTime);

    oscillator.connect(audioContext.destination);
    oscillator.start();

    setTimeout(() => {
        oscillator.stop();
    }, 500);
  }  

  const updatedOrderDetail = () => {
    if (orderDetail !== null && Array.isArray(orderDetail)) {
        const updatedOrderDetail = orderDetail.map(item => {
            const updatedSkuM = item.shelfs.map(sku => {
                if (sku.id_sku == skuid) {
                    return { ...sku, updated: true };
                }
                return sku;
            });
            console.log("updatedSkuM: ", updatedSkuM)
            return { ...item, shelfs: updatedSkuM };
        });
        setOrderDetail(updatedOrderDetail);
        console.log("updatedOrderDetail:", updatedOrderDetail);
        navigate('/menu/online/detail?trackingNo=' + trackingNo + '&type=' + type);
    }
}
  
  useEffect(() => {   
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {        
        setUsername(username);
        if(!type){
          navigate('/menu');
        }
      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    }else{
      navigate('/');
    }
  }, [navigate]);
  

  return (
    <>      
      <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
              Check SKU
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <QrReader
              constraints={{
                facingMode: "environment",
                width: 1920,
                height: 1920,
              //  fps: 30,
              //  zoom: 2
              }}
              onResult={(result, error) => {
                if (!!result) {
                  playBeepSound();
                  setData(result?.text.trim());
                  if(sku==result?.text.trim() || skipSKUCode==result?.text.trim()){
                    updatedOrderDetail()
                  }else{
                    Swal.fire({
                      title: 'SKUสินค้า ไม่ถูกต้อง',
                      icon: 'warning',
                      html: 'SKU: ' + sku + '<br> Scanned Data:' + result?.text,
                      confirmButtonText: 'ตกลง'
                  })
                  }
                  // navigate('/menu/online/detail?trackingNo=' + result?.text + '&type=' + myParam);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: '100%' }}
              autoFocus
            />
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={toggleInput}>
                  {showInput ? 'Cancel Input' : 'Enter SKU Number'}
                </Button>
              </Grid>
              {showInput && (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    label="Enter SKU Number"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                  <Button variant="contained" onClick={() => {
                    if (inputValue.trim() !== '') {
                      playBeepSound();
                      if(sku==inputValue.trim() || skipSKUCode==inputValue.trim()){
                        updatedOrderDetail()
                      }else{
                        Swal.fire({
                          title: 'SKUสินค้า ไม่ถูกต้อง',
                          icon: 'warning',
                          html: 'SKU: ' + sku + '<br> Scanned Data:' + inputValue.trim(),
                          confirmButtonText: 'ตกลง'
                      })
                      }                              
                    }
                  }}>
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5">Scanned Data:</Typography>
              <Typography variant="body1">{data}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            {/* Your back button */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
      </AppBar>
    </>
  );
}

export default ItemScanner;
