import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Container, Button, Box, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { useAxios } from '../../lib/axios'
import Swal from 'sweetalert2'
import { useItemDetail } from '../../context/mainContext';

const PlatformButton = styled(Button)({
  width: '50%', // กำหนดความกว้างเท่ากับ 50% ของพ่อคู่
  fontSize: '1.2rem', // ขนาดตัวอักษร
  margin: 'auto', // จัดตำแหน่งกลางตามแนวนอน
});

function FileUploadModal({ open, onClose, onFileSelected }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    onFileSelected(file);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>เลือกไฟล์ Excel</DialogTitle>
      <DialogContent>
        <DialogContentText>
          โปรดเลือกไฟล์ Excel ที่ต้องการอัพโหลด:
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <input type="file" accept=".xlsx" onChange={handleFileChange} />
        <Button onClick={handleUpload}>อัพโหลด</Button>
      </DialogActions>
    </Dialog>
  );
}

function OnlineMenu() {
  const navigate = useNavigate();
  const axios = useAxios()
  const [open, setOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const { setType } = useItemDetail();

  useEffect(() => {   
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const { username, expiration } = JSON.parse(storedUserData);
      if (expiration && Date.now() < expiration) {
        setUsername(username);

      } else {
        localStorage.removeItem('userData');
        navigate('/');
      }
    }else{
      navigate('/');
    }
  }, [navigate]);
  
  const handleScanBill = (i) => {
    setType(i);
    navigate('/menu/online/qrCodeScanner?type='+i);
  };

  const handleGoBack = () => {
    navigate('/menu');
  };

  const handleGoHome = () => {
    navigate('/menu');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
    setOpen(false);
    setFileModalOpen(true);
  };

  const handleFileUpload = async (file) => {
    // ตรวจสอบว่ามีไฟล์ที่เลือกหรือไม่
    if (!file) {
      alert('โปรดเลือกไฟล์ Excel ก่อน');
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('username',username );
      formData.append('type',selectedPlatform);
  
      // ส่งไฟล์ไปยังเซิร์ฟเวอร์ โดยใช้ Axios
      const response = await axios.post('/online/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // ทำอะไรกับ response ตามที่ต้องการ
      console.log('Upload successful:', response.data);
      Swal.fire({
        title: 'Upload สำเร็จ',
        icon: 'success',
        showCloseButton: false,
        showCancelButton: false,
    })
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        title: 'ไม่สามารถดำเนินการได้',
        text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
        icon: 'error',
        confirmButtonText: 'ตกลง',
        showCloseButton: false,
        showCancelButton: false,
      })
      // จัดการข้อผิดพลาดที่เกิดขึ้น อาจจะแสดงข้อความผิดพลาดหรืออื่น ๆ
    }
  };

  return (
    <>
      <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
            เช็คบิล
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>

      <Container component="main" maxWidth="xs" sx={{ height: '100vh' }}>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                onClick={() => handleScanBill('I')}
                fullWidth
                variant="contained"
                color="success"
                sx={{ p: 5 }}
              >
                <Typography component="h2" variant="h5" align="center">Check Bill</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => handleScanBill('D')}
                fullWidth
                variant="contained"
                color="error"
                sx={{ p: 5 }}
              >
                <Typography component="h2" variant="h5" align="center">Cancel Bill</Typography>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={handleClickOpen}
                fullWidth
                variant="contained"
                color="primary"
                sx={{ p: 5 }}
              >
                <Typography component="h2" variant="h5" align="center">Upload</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>เลือกแพลตฟอร์ม</DialogTitle>
        <DialogContent>
          <DialogContentText>
            โปรดเลือกแพลตฟอร์มที่ต้องการอัพโหลด:
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PlatformButton onClick={() => handlePlatformSelect('lazada')}>Lazada</PlatformButton>
          <PlatformButton onClick={() => handlePlatformSelect('shopee')}>Shopee</PlatformButton>
        </DialogActions>
      </Dialog>

      <FileUploadModal
        open={fileModalOpen}
        onClose={() => setFileModalOpen(false)}
        onFileSelected={handleFileUpload}
      />
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            {/* Your back button */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
        </AppBar>
    </>
  );
}

export default OnlineMenu;
