import React, { useEffect, useState  } from 'react';
import { CustomPaper } from '../../styles';
import Divider from '@mui/material/Divider/Divider';
import { AppBar, Toolbar, Typography, IconButton, Box, Grid,Button } from '@mui/material';
import { ArrowBack, Home } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAxios } from '../../lib/axios'
import Swal from 'sweetalert2'
import { useItemDetail } from '../../context/mainContext'; 


function OnlineDetail({ data }) {
    const navigate = useNavigate();
    const axios = useAxios()
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState('');
    const trackingNo = searchParams.get('trackingNo');
    const { type, orderDetail, setOrderDetail } = useItemDetail();    
    
    const scanItemBySKU = async(e) => {
        const selectedItemSKUid = e.currentTarget.querySelector('#skuid').value;
        const selectedItemSKU = e.currentTarget.querySelector('#sku').value;
        navigate('/menu/online/detail/itemScanner?trackingNo='+trackingNo+'&type='+type+'&skuid='+selectedItemSKUid+'&sku='+selectedItemSKU);
    }

    const handleGoHome = () => {
      navigate('/menu');
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const transformData = (data) => {
      return data.map(order => {
          // แยก SKU ออกเป็นอาร์เรย์และตัด # ออก
          const skuArray = order.sku.split('#')[0].split(',').map(sku => sku.trim());
  
          // สร้างอาร์เรย์ใหม่ของ shelfs
          const newShelfs = skuArray.map((sku, index) => {
              // กรอง shelfs ที่ตรงกับ itemCode
              const matchedShelfs = order.shelfs.filter(shelf => shelf.itemCode === sku);
  
              // รวมชื่อชั้นที่ตรงกับ SKU นี้
              const shelfNames = matchedShelfs.map(shelf => shelf.shelfName).join(',');
  
              if (matchedShelfs.length > 0) {
                  // ถ้าพบ shelf ที่ตรงกัน
                  const shelfData = matchedShelfs[0];
                  return { 
                      ...shelfData,
                      id_sku: `${order.id}_${index + 1}`,
                      skuCode: sku,
                      shelfName: shelfNames // ใช้ชื่อชั้นรวม
                  };
              } else {
                  // ถ้าไม่พบ shelf ที่ตรงกัน
                  return {
                      id: null,
                      shelfName: '', // ไม่มีชื่อชั้น
                      shelfRemark: null,
                      createdDate: null,
                      updatedDate: null,
                      createdBy: null,
                      updatedBy: null,
                      itemCode: null,
                      id_sku: `${order.id}_${index + 1}`,
                      skuCode: sku
                  };
              }
          });
  
          return { 
              ...order, 
              shelfs: newShelfs
          };
      });
  };
   

    const retrieveDetail = async (scannedData) => {
      console.log("trackingNo: ",trackingNo)
      await axios.get('/online/v2', {  params: {
              username: username,
              trackingNo: trackingNo
      }}).then((response) => {
          console.log('Response from server:', response.data);

          // // Process data to set skuid
          // const processedData = response.data.map(item => ({
          //     ...item,
          //     shelfs: item.shelfs.map((sku, index) => ({
          //         ...sku,
          //         id: sku.id || `${item.id}_${index + 1}`
          //     }))
          // // }));

          // setOrderDetail(processedData);

          const transformedData = transformData(response.data);
          console.log(JSON.stringify(transformedData, null, 2));
          setOrderDetail(transformedData);
          if (response.data.length > 0) {
            if(type=="I"){
              const allCheckedOrCanceled = response.data.every(item => item.isChecked);
              if (allCheckedOrCanceled) {
                Swal.fire({
                  title: '*ออเดอร์ซ้ำ*',
                  icon: 'info',
                  text: response.data[0].updatedBy + ' - ' + response.data[0].updatedDate,
                  confirmButtonText: 'ตกลง'
                }).then((result) => {
                  if (result.isConfirmed) {
                    setOrderDetail(null);
                    navigate(`/menu/online/qrCodeScanner?type=${type}`);
                    window.location.reload();
                  }
                });
              }
            }else if(type=="D"){
              const allChecked = response.data.every(item => item.isChecked==0);
              if (allChecked) {
                Swal.fire({
                    title: '*บิลนี้ยังไม่ถูกนำเข้าระบบ*',
                    icon: 'info',
                    confirmButtonText: 'ตกลง'
                  }).then((result) => {
                    console.log("result: " , result)
                    if (result.isConfirmed) {
                      setOrderDetail(null);
                      navigate(`/menu/online/qrCodeScanner?type=${type}`);
                      window.location.reload();
                    }
                  });
              }
              const allCheckedOrCanceled = response.data.every(item => item.isCanceled);
              if (allCheckedOrCanceled) {
                  Swal.fire({
                      title: '*ออเดอร์ซ้ำ*',
                      icon: 'info',
                      text: response.data[0].updatedBy + ' - ' + response.data[0].updatedDate,
                      confirmButtonText: 'ตกลง'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setOrderDetail(null);
                        navigate(`/menu/online/qrCodeScanner?type=${type}`);
                        window.location.reload();
                      }
                    });
              }
            }else{
              Swal.fire({
                title: 'ไม่พบข้อมูล',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
              }).then((result) => {
                if (result.isConfirmed) {
                  setOrderDetail(null);
                  navigate(`/menu/online/qrCodeScanner?type=${type}`);
                  window.location.reload();
                }
              });
            }
            
        } else {
            Swal.fire({
                title: 'ไม่พบข้อมูล',
                icon: 'warning',
                confirmButtonText: 'ตกลง'
              }).then((result) => {
                if (result.isConfirmed) {
                  setOrderDetail(null);
                  navigate(`/menu/online/qrCodeScanner?type=${type}`);
                  window.location.reload();
                }
              });
        }
      }).catch((error) => {
        console.log('Full error object:', error);
        console.error('Error response data:', error.response?.data);
  
        const errorMessage = error.response?.data?.message || 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่';
        Swal.fire({
          title: 'ไม่สามารถดำเนินการได้',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'ตกลง',
          showCloseButton: false,
          showCancelButton: false,
        }).then(() => {
          // navigate('/menu')
        });
      });    
  };
  

    const handleSendBill = async () => {
        try {
            const dataToSend = orderDetail.map(id => ({
                id: id.id,
                checked: type === "I" ? true : false,
                canceled: type === "D" ? true : false
            }));
            console.log("orderDetail",orderDetail)
            console.log("dataToSend",dataToSend)
    
            const response = await axios.post('/online?username=' + username, dataToSend, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            console.log('Update successful:', response.data);
            Swal.fire({
                title: 'Update สำเร็จ',
                icon: 'success',
                showCloseButton: false,
                showCancelButton: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  setOrderDetail(null);
                  navigate('/menu/online');
                }
              });
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                title: 'ไม่สามารถดำเนินการได้',
                text: 'กรุณาลองใหม่อีกครั้ง หรือติดต่อเจ้าหน้าที่',
                icon: 'error',
                confirmButtonText: 'ตกลง',
                showCloseButton: false,
                showCancelButton: false,
            });
        }
    };
    
    useEffect(() => {   
        const storedUserData = localStorage.getItem('userData');
        if (storedUserData) {
          const { username, expiration } = JSON.parse(storedUserData);
          if (expiration && Date.now() < expiration) {
            setUsername(username);
            if(!type){
              navigate('/menu');
              return
            }
            if(!orderDetail){
                retrieveDetail();
            }
          } else {
            localStorage.removeItem('userData');
            navigate('/');
          }
        }else{
          navigate('/');
        }
    }, [navigate,retrieveDetail]);

    let itemCounter = 0;

    return (
        <>
        <AppBar position="static" sx={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={handleGoHome}
          >
            <Home />
            <Typography variant="h6" component="div">
            Detail: {type=='I'?'Check Bill':'Cancel Bill'}
            </Typography>
          </IconButton>
          
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant="h6" component="div" sx={{ textAlign: 'right' }}>
            {username}
          </Typography>
        </Toolbar>
      </AppBar>

      <CustomPaper sx={{ paddingBottom: '80px' }}>
      {orderDetail && orderDetail.map((item, index) => {
        return(
          <React.Fragment key={index}>            
              <Typography variant='h6'>ออเดอร์ : {item.trackingNo}</Typography>
              {item.shelfs && item.shelfs.map((sku, skuIndex) => {
                itemCounter += 1;
                console.log(`Rendering sku with id: ${sku.id}, updated: ${sku.updated}`);
                console.log(orderDetail && orderDetail.length > 0);
                console.log(orderDetail.every(item => item.shelfs && item.shelfs.every(sku => sku.updated)));
                return (
                    <React.Fragment key={`${index}-${skuIndex}`}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Box border={1} borderRadius={5} padding={2} onClick={(e) => scanItemBySKU(e)} style={{ backgroundColor: sku.updated ? 'green' : 'inherit', pointerEvents: sku.updated ? 'none' : 'auto' }}>
                                    {/* ใช้ itemCounter แทน index + 1 */}
                                    <Typography variant='h6'>ITEM {itemCounter}</Typography>
                                    <Typography variant='h5' style={{fontWeight: 'bold'}}>CODE: {sku.skuCode}</Typography>
                                    <Typography variant='h6'>ชื่อ: {item.itemName}</Typography>
                                    <Typography variant='h6'>LOC: {sku.shelfName}</Typography>
                                    {sku.updated}
                                    <input id="skuid" name="skuid" type='hidden' value={sku.id_sku}></input>
                                    <input id="sku" name="sku" type='hidden' value={sku.skuCode}></input>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: '20px' }} />
                    </React.Fragment>
                );
              })}
              
            
          </React.Fragment>
        )
      
      })}
        {orderDetail && orderDetail.length > 0 && orderDetail.every(item => item.shelfs && item.shelfs.every(sku => sku.updated)) ? (
          <Box mt={4} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleSendBill}>
                  SEND
              </Button>
          </Box>
        ) : null} 
              
        </CustomPaper>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)' }}>
          <Toolbar>
            {/* Your back button */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          </Toolbar>
        </AppBar>
        </>
    );
}

export default OnlineDetail;
